const BASE_API_URL = 'https://www.eparconnectserver.com';
const STAGE = 'qa';

export const AWS_API_CONFIG = {
    baseApiUrls: {
        siteValueAPI:`${BASE_API_URL}/${STAGE}-site-value-api/`,
        adminCommonAPI: `${BASE_API_URL}/${STAGE}-admin-api/${STAGE}/`,
        externalCertificationAPI : `${BASE_API_URL}/${STAGE}-ext-certification-api/`,
        documentAPI : `${BASE_API_URL}/${STAGE}-document-api/${STAGE}/`,
        taskAPI : `${BASE_API_URL}/${STAGE}-task-api/${STAGE}/`,
        inspectionAPI : `${BASE_API_URL}/${STAGE}-inspection-api/${STAGE}/`,
        commentsAPI : `${BASE_API_URL}/${STAGE}-comments-api/${STAGE}/`,
        activitiesAPI : `${BASE_API_URL}/${STAGE}-activities-api/${STAGE}/`
    }
}


export const AWS_CONGITO_CONFIG = {
    Auth: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_osCZOviCl',
        userPoolWebClientId: '7gt9spt4sti7v9l0o1k7jpuepd',
        identityPoolId: 'us-west-2:56a8cd09-217a-46bf-9290-4e201cc9bcae'
    }
}

export const CONFIG = {
    s3HostUrl: 'https://s3-us-west-1.amazonaws.com',
    externalCertificationDataBucket: `${STAGE}-us-external-certification-data`,
    connectOUCredentialBucket: `${STAGE}-us-connect-ou-credential`,
}